
    <header>
    <!-- Home Section -->
      <section id="section-home">
        <div class="container p-0">
          <div class="content d-flex justify-content-between align-items-center">
            <h1 class="page-title text-white">Contacts</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home" class="text-reset">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contacts</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
    </header>

    <section id="section1" class="py-5">
      <div class="container px-0 py-5">
        <div class="row m-0">
          <div class="col-lg-6">
            <div class="header-content mb-4">
              <span>
                {{contactInfo?.smallTitle}}
              </span>
              <h2>
                {{contactInfo?.bigTitle1}}
                {{contactInfo?.bigTitle2}}
              </h2>	   
              <p class="text-muted my-3" style="width: 90%;">
                {{contactInfo?.details}}
                </p>
            </div>
            <div class="contact-icons d-flex align-items-center p-4">
              <i class="fas fa-map-marker-alt fa-2x mr-4 mb-2 text-main3"></i>
              <div class="info-text">
			        	<h6>Our Address:</h6>
			        	<p class="m-0 text-muted">
                  {{contactInfo?.address}}
                </p>
			        </div>
            </div>
            <div class="contact-icons d-flex align-items-center p-4">
              <i class="far fa-envelope fa-2x mr-4 mb-2 text-main3"></i>
              <div class="info-text">
			        	<h6>Our Mailbox:</h6>
			        	<p class="m-0 text-muted">
                  {{contactInfo?.email}}
                </p>
			        </div>
            </div>
            <div class="contact-icons d-flex align-items-center p-4">
              <i class="fas fa-phone fa-2x mr-4 mb-2 text-main3"></i>
              <div class="info-text">
			        	<h6>Our Phone:</h6>
			        	<p class="m-0 text-muted">
                  {{contactInfo?.phone}}
                </p>
			        </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="contact-form">
              <form [formGroup]="sendEmail" (ngSubmit)="onSubmit(sendEmail.value)" >
                <h2>Ready to Get Started?</h2>
                <small><span>Your email address will not be published. Required fields are marked *</span></small>
                <div class="form-group mt-5">
                  <input type="text" class="contact-form-control" placeholder="Your Name *" formControlName="userName">
                  <span *ngIf= "sendEmail.controls.userName.invalid && sendEmail.controls.userName.touched"  class="text-danger">
                    *This field is required
                  </span> 
                </div>
                <div class="form-group">
                  <input type="email" class="contact-form-control" placeholder="Your Email *"  formControlName="email">
                  <div *ngIf= "sendEmail.controls.email.invalid && sendEmail.controls.email.touched"  class="text-danger">
                   *Please enter a valid email
                  </div>
                </div>
                <div class="form-group">
                  <textarea class="contact-form-control" cols="30" rows="8" placeholder="Message..."
                  formControlName="message"></textarea>
                  <div *ngIf= "sendEmail.controls.message.invalid && sendEmail.controls.message.touched"  class="text-danger">
                    *This field is required
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn submit contact-btn" type="submit" [disabled]="sendEmail.invalid">SEND MESSAGE</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1726.3997751744105!2d31.25589439740305!3d30.071279721556493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458407dc659f28f%3A0x5bc3ade9a189cd15!2sMahmashet%2C%20Al-Sharabiya%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1623092417365!5m2!1sen!2seg"
      style="border:0;" allowfullscreen="" loading="lazy" title="Mahmashet St, Al-Sharabiya,Cairo, Egypt" aria-label="Mahmashet St, Al-Sharabiya,Cairo, Egypt"
      frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="800"></iframe>
