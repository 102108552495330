<div class="main py-5">

    <section class="signup">
        <div class="container">
            <div class="signup-content">
                <form id="signup-form" class="signup-form" [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
                    <h2 class="form-title">Create account</h2>
                    <div class="form-group">
                        <input type="text" class="form-input"  placeholder="*Your First Name" autocomplete="off" formControlName="firstName">
                        <div *ngIf="registerForm.controls.firstName.invalid && registerForm.controls.firstName.touched"  class="text-danger">
                            *This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-input"  placeholder="*Your Last Name" autocomplete="off" formControlName="lastName">
                        <div *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.touched"  class="text-danger">
                            *This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-input"  placeholder="*Enter your nickname" autocomplete="off" formControlName="userName">
                        <div *ngIf="registerForm.controls.userName.invalid && registerForm.controls.userName.touched"  class="text-danger">
                            *This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-input" name="email" id="email" placeholder="*Your Email" autocomplete="off" formControlName="email">
                        <div *ngIf="registerForm.controls.email.invalid && registerForm.controls.email.touched"  class="text-danger">
                            *This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-input" name="password" id="password" placeholder="*Password" autocomplete="off" formControlName="password">
                        <span toggle="#password" class="zmdi field-icon toggle-password zmdi-eye"></span>
                        <div *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.touched"  class="text-danger">
                            Password must be at least 6 characters, <br>
                            Password must have at least one non alphanumeric character, <br>
                            Password must have at least one number, <br>
                            Password must have at least one lowercase ('a'-'z'), <br>
                            Password must have at least one uppercase ('A'-'Z').
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <input type="password" class="form-input" name="re_password" id="re_password" placeholder="Repeat your password" autocomplete="off" formControlName="confirmPassword">
                        <div *ngIf="registerForm.controls.confirmPassword.invalid && registerForm.controls.confirmPassword.touched"  class="text-danger">
                            *This field is required
                        </div>
                    </div> -->
                    <!-- <div class="form-group">
                        <input type="checkbox" name="agree-term" id="agree-term" class="agree-term" formControlName="agreeOnTerms">
                        <label for="agree-term" class="label-agree-term"><span><span></span></span>I agree all statements in  <a href="#" class="term-service">Terms of service</a></label>
                        <div *ngIf="registerForm.controls.agreeOnTerms.invalid && registerForm.controls.agreeOnTerms.touched"  class="text-danger">
                            *This field is required
                        </div>
                    </div> -->
                    <div class="form-group">
                        <button id="submit" class="form-submit">Sign up</button>
                        <small *ngIf="ErrorMessage"  class="text-danger d-flex justify-content-center mt-3">
                            {{ErrorMessage }}
                        </small>
                    </div>
                </form>
                <p class="loginhere">
                    Have already an account ? <a routerLink="/login" class="loginhere-link">Login here</a>
                </p>
            </div>
        </div>
    </section>

</div>